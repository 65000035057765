<template>
  <div id="mian">
    <!-- 上面表单框 -->
    <div class="con_from">
      <div class="lineP">
        <div class="item">
          <i>商户名称</i>
          <el-input
            v-model="query.keyword"
            maxlength="128"
            suffix-icon="el-icon-search"
            placeholder="商户名称/编号"
          ></el-input>
        </div>
        <div class="item itemTime">
          <i>账单日期</i>
          <el-date-picker
            v-model="query.startDate"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择时间"
          ></el-date-picker>
          <span style="padding-left: 10px; padding-right: 10px">-</span>
          <el-date-picker
            v-model="query.endDate"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择时间"
          ></el-date-picker>
        </div>
      </div>
      <div class="lineP">
        <div class="item">
          <i>账单状态</i>
          <el-select
            v-model="query.billStatus"
            placeholder="全部"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="已生成" value="SUCCESS"></el-option>
            <el-option label="生成失败" value="FAIL"></el-option>
            <el-option label="无交易" value="NO_TRADE"></el-option>
          </el-select>
        </div>
        <div class="itembtn">
          <el-button class="searchBt" @click="getBillsData">查询</el-button>
          <el-button class="searchBt" @click="resetRun" v-auth="'DATA:BILLS:ORDERS/REPLAY'">重新执行</el-button>
        </div>
      </div>
      <div class="lineP">
        <div class="item" style="height:auto;width:100%;">
          <i>账单类型</i>
          <div style="width:calc(100% - 60px - 10px)">
            <span
              class="type_bt"
              :class="query.billType == '' ? 'active' : ''"
              @click="selectBillType('')"
              >全部</span
            >
            <span
              class="type_bt"
              :class="query.billType == 'RECEIPT' ? 'active' : ''"
              @click="selectBillType('RECEIPT')"
              >收款交易</span
            >
            <span
              class="type_bt"
              :class="query.billType == 'REFUND' ? 'active' : ''"
              @click="selectBillType('REFUND')"
              >退款交易</span
            >
            <span
              class="type_bt"
              :class="query.billType == 'PAY' ? 'active' : ''"
              @click="selectBillType('PAY')"
              >付款交易</span
            >
            <span
              class="type_bt"
              :class="query.billType == 'EXCHANGE' ? 'active' : ''"
              @click="selectBillType('EXCHANGE')"
              >退汇交易</span
            >
            <template>
              <span
                class="type_bt"
                :class="query.billType == 'ALLOCATE_TRADE' ? 'active' : ''"
                @click="selectBillType('ALLOCATE_TRADE')"
                >分账收款</span
              >
              <span
                class="type_bt"
                :class="query.billType == 'ALLOCATE_REFUND' ? 'active' : ''"
                @click="selectBillType('ALLOCATE_REFUND')"
                >分账退款</span
              >
              <span
                class="type_bt"
                :class="query.billType == 'ALLOCATE_SETT' ? 'active' : ''"
                @click="selectBillType('ALLOCATE_SETT')"
                >分账结算</span
              >
              <span
                class="type_bt"
                :class="query.billType == 'TRADEINCOME' ? 'active' : ''"
                @click="selectBillType('TRADEINCOME')"
                >平台商户分账收款</span
              >
              <span
                class="type_bt"
                :class="query.billType == 'PLAT_REFUND' ? 'active' : ''"
                @click="selectBillType('PLAT_REFUND')"
                >平台商户分账退款</span
              >
              <span
                class="type_bt"
                :class="query.billType == 'PAYMENTORDER' ? 'active' : ''"
                @click="selectBillType('PAYMENTORDER')"
                >平台商户支付订单</span
              >
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="tab1">
      <table class="tab">
        <tr class="tab_title">
          <td>
            <p>账单日期</p>
          </td>
          <td>
            <p>商户名称</p>
          </td>
          <td>
            <p>商户编号</p>
          </td>
          <td>
            <p>账单类型</p>
          </td>
          <td>
            <p>账单状态</p>
          </td>
          <td style="width: 100px">
            <p>操作</p>
          </td>
        </tr>
        <tr v-for="(v, i) in tabData" :key="i">
          <td>
            <p>{{ v.billDate }}</p>
          </td>
          <td>
            <p>{{ v.mchName }}</p>
          </td>
          <td>
            <p>{{ v.mchId }}</p>
          </td>
          <td>
            <p>{{ billType[v.billType] }}</p>
          </td>
          <td>
            <p>{{ billStatus[v.billStatus] }}</p>
          </td>
          <td>
            <p v-auth="'DATA:BILLS:ORDERS/DOWNLOAD'">
              <i
                v-if="v.billStatus == 'SUCCESS'"
                class="lianjie"
                @click="downloadBill(v)"
                >下载</i
              >
              <i
                v-if="v.billStatus == 'loading'"
                class="lianjie"
                style="color: #ccc"
                >下载中</i
              >
            </p>
          </td>
        </tr>
      </table>
    </div>
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="query.pageNo"
      :page-sizes="[10, 20, 50,100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalNum"
    ></el-pagination>
    <el-dialog title="重新执行" :visible.sync="resetRunShow" width="490px">
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title">商户名称</div>
          <el-select v-model="resetRunForm.mchId" placeholder="选择商户">
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="v in mchDrop"
              :key="v.mchId"
              :label="v.mchName"
              :value="v.mchId"
            ></el-option>
          </el-select>
          <div class="overAccount_ul_title">账单类型</div>
          <el-select v-model="resetRunForm.billType" placeholder="选择账单类型">
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="(v, i) in billType"
              :key="i"
              :label="v"
              :value="i"
            ></el-option>
          </el-select>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i>账单日期</div>
          <el-date-picker
            style="width: 200px; height: 36px"
            v-model="resetRunForm.billDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择时间"
          >
          </el-date-picker>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="resetRunShow = false"
          >取 消</el-button
        >
        <el-button class="addBt" @click="resetRunSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { formatDate } from "@/utils/common.js";
import { getBillsData, downloadBills, setResetRun } from "@/api/data/bill.js";
import { mapState, mapMutations } from "vuex";
import { merchantsDrop } from "@/api/common.js";
export default {
  data() {
    return {
      query: {
        endDate: formatDate(+new Date() - 24 * 60 * 60 * 1000, "yyyy-MM-dd"),
        startDate: formatDate(+new Date() - 24 * 60 * 60 * 1000, "yyyy-MM-dd"),
        keyword: "",
        billType: "",
        pageNo: 1,
        pageSize: 10,
        billStatus: "",
      },
      tabData: [],
      totalNum: 0,
      billType: {
        RECEIPT: "收款交易",
        REFUND: "退款交易",
        PAY: "付款交易",
        EXCHANGE: "退汇交易",
        ALLOCATE_TRADE: "分账收款",
        ALLOCATE_REFUND: "分账退款",
        ALLOCATE_SETT: "分账结算",
        TRADEINCOME: "平台商户分账收款",
        PLAT_REFUND: "平台商户分账退款",
        PAYMENTORDER: "平台商户支付订单",
      },
      billStatus: {
        PROCESSING: "未生成",
        FAIL: "生成失败",
        SUCCESS: "已生成",
        NO_TRADE: "无交易",
        loading: "已生成",
      },
      resetRunShow: false,
      mchDrop: [],
      resetRunForm: {
        mchId: "",
        billDate: "",
        billType: "",
      },
    };
  },
  computed: {
    ...mapState({
      searchQueryDetail: "bill_searchQuery",
    }),
  },
  created() {
    if (this.searchQueryDetail) {
      this.query = this.searchQueryDetail.query;
    }
    this.getBillsData(false);
    this.getDrop();
    this.$enter(this.$route.path, this.getBillsData);
  },
  beforeDestroy() {
    console.log("beforeDestroybeforeDestroy", this.query);

    let data = {
      query: this.query,
    };
    this.setSearchQuery(data);
  },
  methods: {
    ...mapMutations({
      setSearchQuery: "bill_setSearchQuery",
    }),
    // 获取账单列表
    getBillsData(reset = true) {
      if (reset) {
        this.query.pageNo = 1;
        this.query.keyword = this.query.keyword.trim();
      }
      this.query.startDate = this.query.startDate ? this.query.startDate : "";
      this.query.endDate = this.query.endDate ? this.query.endDate : "";
      this.query.keyword = this.query.keyword.trim();
      if (!this.query.startDate || !this.query.endDate) {
        this.$message.error("账单日期时间段必选");
        return;
      }
      getBillsData(this.query).then((res) => {
        if (res.resultStatus) {
          this.tabData = res.resultData.bills;
          if (!this.tabData || this.tabData.length == 0) {
            this.$message({
              message: "暂无数据",
              duration: 1500,
            });
          }
          this.totalNum = res.resultData.totalNum;
        }
      });
    },
    // 切换账单类型
    selectBillType(type) {
      this.query.billType = type;
      this.getBillsData();
    },
    // 下载
    downloadBill(value) {
      value.billStatus = "loading";
      let data = {
        mchId: value.mchId,
        billDate: value.billDate,
        billType: value.billType,
      };
      downloadBills(data).then((res) => {
        value.billStatus = "SUCCESS";
        if (res) {
          let url = window.URL.createObjectURL(new Blob([res]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute(
            "download",
            `${value.mchId}_${this.billType[value.billType]}.csv`
          );
          document.body.appendChild(link);
          link.click();
        }
      });
    },

    // 获取下拉
    getDrop() {
      merchantsDrop().then((res) => {
        if (res.resultStatus) {
          this.mchDrop = res.resultData;
        }
      });
    },
    // 重新执行
    resetRun() {
      this.resetRunForm = {
        mchId: "",
        billDate: "",
        billType: "",
      };
      this.resetRunShow = true;
    },
    resetRunSubmit() {
      const data = { ...this.resetRunForm };
      if (!data.billDate) {
        return this.$message.error("账单日期必填");
      }
      setResetRun(data).then((res) => {
        if (res.resultStatus) {
          this.$message.success("正在执行");
          this.getBillsData(false);
          this.resetRunShow = false;
        }
      });
    },
    // 页面大小改变
    handleSizeChange(size) {
      this.query.pageSize = size;
      this.getBillsData();
    },
    handleCurrentChange(page) {
      this.query.pageNo = page;
      this.getBillsData(false);
    },
  },
};
</script>
<style scoped>
.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  padding: 0;
}
.type_bt {
  display: inline-block;
  min-width: 72px;
  margin-bottom: 5px;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  color: #000000;
  margin-left: 80px;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  margin: 0px 42px;
  padding: 0px 10px;
  box-sizing: border-box;
}

.type_bt.active {
  color: #ffffff;
  background-color: #48b8b6;
  border-radius: 4px;
  border-bottom: 0;
}

.type_bt:not(.active):hover {
  color: #48b8b6;
}
.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}
.overAccount_ul_title i {
  color: #48b8b6;
  padding-right: 3px;
}
.ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}
</style>
